.right-animate-enter {
  transform: translateX(0);
}
.right-animate-enter-active {
  transform: translateX(-100%);
  transition: transform 500ms;
}
.right-animate-enter-done {
  transform: translateX(-100%);
}

.fade-animate-enter {
  opacity: 0;
  transform: translateX(-100%);
}
.fade-animate-enter-active {
  opacity: 1;
  transition: opacity 500ms;
  transform: translateX(-100%);
}
.fade-animate-enter-done {
  opacity: 1;
  transform: translateX(-100%);
}


.bottom-animate-enter {
  transform: translateY(0);
  background-color: rgba(186, 186, 186, 0);
}
.bottom-animate-enter-active {
  transform: translateY(-100%);
  background-color: rgba(186, 186, 186, 1);
  transition: transform 500ms, background-color 100ms linear 500ms;
}
.bottom-animate-enter-done {
  background-color: rgba(186, 186, 186, 1);
  transform: translateY(-100%);
}

.bottom-animate-exit {
  background-color: rgba(186, 186, 186, 1);
  transform: translateY(-100%);
}
.bottom-animate-exit-active {
  transform: translateY(0);
  background-color: rgba(186, 186, 186, 0);
  transition: transform 500ms ease-in 100ms, background-color 100ms;
}




.bottom-animate-desktop-enter {
  transform: translateY(0);
}
.bottom-animate-desktop-enter-active {
  transform: translateY(-100%);
  transition: transform 500ms;
}
.bottom-animate-desktop-enter-done {
  transform: translateY(-100%);
}

.bottom-animate-desktop-exit {
  transform: translateY(-100%);
}
.bottom-animate-desktop-exit-active {
  transform: translateY(0);
  transition: transform 500ms ease-in 100ms;
}