@import './fonts.css';

html, body {
  margin: 0;
  overscroll-behavior: contain;
  height: 100%;
  width: 100%;
  overflow: hidden;
}

p {
  margin: 0;
}

* {
  box-sizing: border-box;
}

*:not(input){
  user-select: none;
}

svg {
  display: block;
}

body {
  font-family: "Inter", "Apercu Pro", sans-serif;
  color: var(--text-primary);
  -webkit-font-smoothing: antialiased;
  -webkit-overflow-scrolling: auto;
  overflow: hidden;
  pointer-events: none;
}

#root {
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  position: fixed;
  overflow: hidden;
}

div {
	::-webkit-scrollbar {
		height: 0.25rem ;
		width: 0;
	}

	::-webkit-scrollbar-thumb {
		background: var(--scrollbar-thumb);
		-webkit-border-radius: 8px;
	}

	::-webkit-scrollbar-track {
		background: var(--scrollbar-track);
		-webkit-border-radius: 8px;
	}
}

.block-scroll {
  overflow: hidden !important;
}

.onboarding-highlighted-element {
  z-index: var(--z-index-onboarding-showed-element)
}

input {
  font-family: inherit !important;
  font-size: 1rem !important;
}

input[type='date'], input[type='time'] {
  -webkit-appearance: none;
  color: inherit !important;
}

ul, li {
	margin: 0;
	padding: 0;
	list-style: none;
}


@media (min-width: 400px) {
  body {
    background: rgba(248, 248, 248, 1);
  }
}

@media (max-width: 400px) {
  #root {
    background: rgba(248, 248, 248, 1);
  }

}

@media (max-width: 400px) {
  /* хром, сафари */
  div::-webkit-scrollbar {
		width: 0;
		height: 0;
	}

  /* ie 10+ */
  div { -ms-overflow-style: none; }

  /* фф (свойство больше не работает, других способов тоже нет)*/
  div { overflow: -moz-scrollbars-none; }


  * {
    touch-action: pan-x pan-y;
  }
}

@media screen and (max-height: 450px) and (min-aspect-ratio: 13/9) and (orientation: landscape) {
  #root {
      display: none;
  }

  body:before {
      content: 'Поверните устройство в вертикальный режим';
      position: fixed;
      display: flex;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      margin: auto;
      z-index: 999;
      justify-content: center;
      align-items: center;
      width: 80%;
      height: 100px;
  }
}
