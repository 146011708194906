@media print {
    * {
        overflow: visible !important;
    }

    @page {
        size: auto;
        margin: 1em 2em;
    }

    .no-print {
        display: none !important;
    }

    html,
    body {
        -webkit-print-color-adjust: exact !important;
        print-color-adjust: exact !important;
        overflow: visible !important;
        width: 100% !important;
        font-size: 1.125em;
    }

    body {
        margin: 0.5cm;
    }

    #root {
        position: absolute;
        overflow: visible;
        width: 100% !important;
      }
}
