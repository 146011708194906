@font-face {
  font-family: 'Inter';
  font-weight: 400;
  src: url('../fonts/Inter/Inter_18pt-Regular.ttf');
}

@font-face {
  font-family: 'Inter';
  font-weight: 500;
  src: url('../fonts/Inter/Inter_18pt-Medium.ttf');
}

@font-face {
  font-family: 'Inter';
  font-weight: 700;
  src: url('../fonts/Inter/Inter_18pt-Bold.ttf');
}

@font-face {
  font-family: 'Apercu Pro';
  src: url('../fonts/ApercuPro-Regular.woff2') format('woff2'),
  url('../fonts/ApercuPro-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Apercu Pro";
  src: url("../fonts/ApercuPro-Medium.woff2") format("woff2"), url("../fonts/ApercuPro-Medium.woff") format("woff");
  font-weight: 500;
  font-style: normal;
}
